@use "./../App" as *;

.main-block {
  display: flex;
  flex-direction: column;
  padding: {
    left: $padding--small;
    right: $padding--small;
  };
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}

.main-block__section {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  flex: 1;
  /*box-sizing: border-box;
  padding-bottom: $padding--small;*/
}

.input-box {
  flex-grow: 1;
  max-height: 50%;
  flex-basis: 50%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  min-height: calc(50% - #{$padding--small});
}

.box__character-count {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #666666;
  opacity: 0.5;
  font-size: 14px;
  line-height: 16px;
}

.div__textarea {
  width: 100%;
  height: auto;
  line-height: 1.6em;
}

.output-box {
  height: calc(50% - #{$padding--small});
  margin: {
    top: 20px;
    bottom: 20px;
  };
  max-height: 50%;
  flex-basis: 50%;
  font-size: $fs--small;
  line-height: 24px;
  border-radius: 10px;
  padding: {
    top: 14px;
    bottom: 14px;
    left: $padding--small;
    right: $padding--small;
  };

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $clr--white;
  overflow-y: auto;
}

textarea {
  font-family: $font-family;
  font-size: $fs--small;
  line-height: 24px;
  resize: none;
  border: none;
  border-radius: 10px;
  height: 100%;
  flex-grow: 1;
  padding: {
    top: 14px;
    bottom: 14px;
    left: $padding--small;
    right: $padding--small;
  };

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(77, 182, 172, 0.4);
  }

  &::placeholder {
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &::-webkit-input-placeholder {
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &:-moz-placeholder { /* Firefox 18- */
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }

  &:-ms-input-placeholder {
    opacity: 0.4;
    color: $clr--dark-gray;
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
    font-family: $font-family;
  }
}

.section__button {
  box-sizing: border-box;
  height: 42px;
  width: 177px;
  border-radius: 21px;
  margin: {
    top: 22px;
    bottom: 2px;
    left: auto;
    right: auto;
  }
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: $font-family;
  display: block;
  cursor: pointer;
  background-color: $clr--purple;
  border: 0;
}

@media screen and (min-width: 768px) {
  .main-block {
    padding: {
      left: $margin--normal;
      right: $margin--normal;
    }
  }
}

@media screen and (min-width: 1024px) {
  .main-block {
    border-bottom: 0;
  }

  .main-block__section {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    min-height: auto;
  }

  .input-box, .output-box {
    flex: 0 0 49.5%;
    margin: {
      bottom: 20px;
      top: 0;
      left: 0;
      right: 0;
    }
    order: 1;
    margin: 0;
    overflow-y: auto;
  }

  .section__button {
    order: 2;
    margin: {
      top: 20px;
      bottom: 0;
    }
  }

  .output-box {
    padding: 24px;
    font-family: $font-family;
    height: auto;

    span {
      font-size: $fs--h2;
      line-height: 1.6em;
      letter-spacing: 0;
      white-space: pre-wrap;
    }

    mark {
      margin: 0 0.25em 0 0 !important;
    }
  }

  textarea {
    font-size: $fs--h2;
    line-height: 35px;
    letter-spacing: 0;
    padding: 24px;

    &::placeholder {
      font-size: $fs--h2;
      line-height: 35px;
    }

    &::-webkit-input-placeholder {
      font-size: $fs--h2;
      line-height: 35px;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: $fs--h2;
      line-height: 35px;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
      font-size: $fs--h2;
      line-height: 35px;
    }

    &:-ms-input-placeholder {
      font-size: $fs--h2;
      line-height: 35px;
    }
  }
}

/* Scroll down container */
.scroll-container {
  display: none;
  align-items: center;
  $size: 20px;
  margin: {
    top: $size;
    bottom: $size;
  }
}

.section__image--scroll {
  width: 27px;
  margin-right: $padding--normal;
}

.section__subtitle {
  color: $clr--dark-gray;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}

.section__subtitle--scroll {
  margin-bottom: 0;
}


@media screen and (min-width: 1024px) {
  .scroll-container {
    display: flex;
  }
}
