@use "../../App.scss" as *;

header {
  padding: {
    //top: $padding--normal;
    //bottom: $padding--small;
    //left: $padding--small;
    //right: $padding--small;
  }

  display: flex;
  flex-direction: column;
}

.header--nav-open {
  position: fixed;
  z-index: 1000;
  background-color: $clr--light-gray;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.header--active {
  background-color: $clr--white;

  & .nav__list {
    background-color: $clr--white;
  }
}

.nav {
  //width: 100%;
  padding: {
    left: $padding--small;
    right: $padding--small;
    bottom: $padding--normal;
    top: $padding--small;
  };
}

.nav__list {
  /*position: fixed;*/
  background: $clr--light-gray;
  /*top: 0;
  bottom: 0;
  left: 0;
  right: 0;*/
  z-index: 100;

  /*transform: translateX(1000%);
  transition: transform 250ms cubic-bezier(.5, 0, .5, 1);*/
}

.nav__list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /*height: 100%;*/
  /*flex-direction: column;*/
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav__item {
  width: 100%;
  order: 2;
  text-align: center;
  display: none;
  padding: {
    top: $padding--very-small;
    bottom: $padding--very-small;
  };
  font-size: $fs--middle;
  letter-spacing: 0;
  line-height: 28px;
}

.nav__item--active {
  display: block;
}

.nav__link {
  color: $clr--purple;
  text-decoration: none;
}

.logo {
  width: 130px;
  order: 1;
  display: inline-block;
  padding: 0;
}

.toggle {
  width: 20px;
  font-size: 20px;
  order: 1;
  display: inline-block;
  padding: 0;
}

.toggle--dropdown path {
  fill: $clr--blue;
}

/*.toggle {
  transition: opacity 0.5s linear;
}

.toggle--hidden {
  opacity: 0;
}*/

.nav__button {
  order: 3;
  display: none;
  cursor: pointer;
}

.nav__lang-change {
  width: auto;
  color: $clr--dark-gray;
  opacity: 0.5;
  margin-left: calc(50% - 43px);
  padding-right: 7px;
  cursor: pointer;
}

.nav__lang-change + .nav__lang-change {
  margin: {
    right: calc(50% - 43px);
    left: 0;
  }
  padding: {
    left: 7px;
    right: 0;
  }
}

.nav__lang-change--active {
  color: $clr--purple;
  font-weight: 600;
  opacity: 1.0;
}

.nav__link--sticker {
  position: relative;
}

.sticker--new {
  background-color: $clr--purple;
  color: $clr--white;
  font-size: 10px;
  padding: 4px;
  border-radius: 6px;
  position: absolute;
  top: -8px;
  line-height: 10px;
}

@media screen and (min-width: 768px) {
  .nav {
    padding: {
      left: $margin--normal;
      right: $margin--normal;
      top: $padding--normal;
    };
  }
}

@media screen and (min-width: 1024px) {
  .header--active {
    background-color: $clr--light-gray;

    .nav__list {
      background-color: $clr--light-gray;
    }
  }

  .nav {
    bottom: 45px;

    &__list {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .nav__item {
    display: inline-block;
    width: auto;
    font-size: $fs--small;

    padding: {
      left: clamp(5px, 20px, 30px);
      right: clamp(5px, 20px, 30px);
    };
  }

  .logo {
    width: 163px;
    min-width: 163px;
    margin-right: auto;
    padding: 0 !important;
    white-space: normal;
  }

  .toggle {
    display: none;
  }

  .nav__button {
    order: 3;
    box-sizing: border-box;
    height: 32px;
    min-width: 135px;
    border: 1px solid $clr--purple;
    border-radius: 21px;
    padding: 0;
    color: $clr--purple;
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    margin-left: min(20px, 40px);
  }

  .nav__item.nav__lang-change, .nav__lang-change + .nav__lang-change {
    margin: 0;
    width: auto;
    padding-right: 8px;
  }

  .nav__link--sticker {
    white-space: nowrap;
  }
}

@media screen and (min-width: 1280px) {
  .nav__item {
    padding: {
      left: clamp(20px, 30px, 40px);
      right: clamp(20px, 30px, 40px);
    };
  }
}