@use '../../App' as *;

.demo-overlay {
  background-color: $clr--dark-gray;
  height: 100%;
  z-index: 1000;
  display: none;

  a {
    text-decoration: none;
    color: $clr__white;
  }
}

.demo-overlay--active {
  //left: 50%;
  display: block;
  height: 100%;
}

.aside__title, .aside__subtitle {
  color: $clr--white;
  font-size: $fs--small;

  padding: {
    left: $padding--small;
    right: $padding--small;
    top: $padding--normal;
  };

  a {
    color: $clr--yellow;
  }
}

.aside__title {
  //opacity: 0.6;
  font-weight: $fw--bold;
  margin-bottom: $margin--small;
  letter-spacing: 0;
  line-height: 19px;
}

.aside__subtitle {
  letter-spacing: 0.7px;
  line-height: 24px;
}

.aside__divider {
  display: flex;
  align-items: center;
  padding: {
    top: $padding--normal;
    bottom: $padding--normal;
    right: $padding--big;
    left: $padding--big;
  };
  cursor: pointer;
  width: fit-content;
}

.aside__button {
  margin-right: $margin__small;
  //height: 100%;

  path {
    font-size: $fs--small !important;
    fill: $clr__white;
  }
}

@media screen and (min-width: 768px) {
  .aside__subtitle {
    padding: {
      left: $margin--normal;
      right: $margin--normal;
    };
  }
}

@media screen and (min-width: 1024px) {
  .demo-overlay {
    display: flex;
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    width: 0;
    flex-direction: column;
    overflow-x: hidden;
    z-index: 1000;

    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .aside--active {
    width: 25%;
    min-width: 350px;
  }

  .aside__subtitle {
    margin-top: auto;
  }

  .aside__title  {
    letter-spacing: $letter-spacing--small;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
  }

  .aside__button {
    font-size: 20px;
  }

  .aside__divider {
    padding: {
      bottom: 10px;
      top: 25px;
    }
  }
}