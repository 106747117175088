@use "../../App" as *;

.demo-item {
  display: flex;
  align-items: center;
  padding: {
    top: $padding--normal;
    bottom: $padding--normal;
    left: $padding--small;
    right: $padding--small;
  }

  border-bottom: 1px solid rgba(255,255,255,0.2);
}

.div__image {
  $size: 56px;
  width: $size;
  height: $size;
  border-radius: 100%;
  display: flex;
  margin-right: $padding--normal;
  flex-shrink: 0;

  div {
    height: auto;
    width: 100%;
    padding: $padding--small;
  }

  &--red {
    background-color: $clr--red;
  }

  &--white {
    background-color: $clr--white;
  }

  &--yellow {
    background-color: $clr--yellow;
  }

  &--purple {
    background-color: $clr--purple;
  }

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.section__demo-item {
  display: flex;
  flex-direction: column;
}

.section__title {
  font-size: $fs--h3;
  font-weight: $fw--bold;
  letter-spacing: 0;
  line-height: 28px;
  color: $clr--white;
  margin-bottom: 0;
}

.section__subtitle--demo-item {
  font-size: $fs--small;
  letter-spacing: $letter-spacing--small;
  line-height: 35px;
  margin-bottom: 0;
  word-break: break-word;
}

.arrow--demo-item {
  display: none;
  width: 22px;
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .demo-item {
    padding: {
      left: $padding--big;
      right: $padding--big;
    };

    h3 {
      font-size: $fs--h2;
      line-height: 40px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .section__demo-item {
    margin-right: 10px;
  }

  .arrow--demo-item {
    display: block;
    margin-left: auto;
  }
}