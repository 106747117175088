@use "../App.scss" as *;

/* Info and description blocks */

.info {
  margin: {
    right: $margin--small;
    left: $margin--small;
    bottom: 50px;
  };
  padding-top: $padding--small;

  p {
    font-size: $fs--small;
    letter-spacing: 0;
    line-height: 24px;
  }
}

@media screen and (min-width: 768px) {
  .info {
    margin: {
      right: $margin--normal;
      left: $margin--normal;
    };

    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .info {
    margin: {
      left: $margin--big;
      right: $margin--extrabig;
    };
  }
}